import React from "react";

class Footer extends React.Component {
	render() {
		return (
			<footer style={footerStyle}>
				<div style={innerStyle}>
					<p>
						<b>ChenCreations</b> - 2025
					</p>
				</div>
			</footer>
		);
	}
}

const footerStyle = {
	width: "100%",
	height: "70px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	opacity: "0",
	animation: "fadeIn 2s ease 2s forwards",
};

const innerStyle = {
	width: "90%", // This will make the content and the border span 90% of the width
	padding: "10px 0",
	borderTop: "1px solid #CECECF",
	textAlign: "center", // This will center the text
	color: "#333",
	fontSize: "1em",
};

export default Footer;
